import bootstrap from 'bootstrap'
import jQuery from 'jquery'
import bsCustomFileInput from 'bs-custom-file-input'

jQuery(() => {
    bsCustomFileInput.init()
})

export default {
    bootstrap
}